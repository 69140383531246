import { Template } from './_templateData';

export const TillfalligUtOchAterinforselAvHast: Template = {
  id: 6,
  actorType: 'horse',
  newIssueButton: true,
  fields: [
    {
      name: 'Vilken häst gäller utförseln/återinförseln?',
      description:
        'Välj den häst som ska anmälas för tillfällig utförsel alternativt den häst som har återinförts till Sverige.',
      fields: [
        'horseSwitchButtonTemporaryExport',
        'horseSearchInfo',
        'horseExportInfo',
        'horseSearch',
        'horseImport',
        'exportPersonLabel',
        'exportPersonName',
        'exportPersonAddress1',
        'exportPersonAddress2',
        'exportPersonZipAndCity',
        'exportPersonRegion',
        'exportPersonCountry',
      ],
    },
    {
      name: 'Vilket destinationsland och uppstallningsplats?',
      description: 'Ange uppstallningsplatsens namn och adress.',
      fields: [
        'exportName',
        'exportAddress1',
        'exportAddress2',
        'exportZipAndCity',
        'exportRegion',
        'exportCountry',
      ],
      hidden: 'values?.horseSwitchButtonTemporaryExport',
    },
    {
      name: 'Vilken är orsaken till utförseln?',
      description:
        'Välj orsak till att hästen utförs och ange eventuella övriga uppgifter som efterfrågas.',
      fields: [
        'exportReason',
        'exportAuction',
        'exportStallionName',
        'exportMareStatus',
        'exportStallionCoveredName',
        'exportFoalSex',
        'exportFoalFather',
        'exportFoalNumber',
        'exportFoalChipNr',
        'exportFoalinfo',
      ],
      hidden: 'values?.horseSwitchButtonTemporaryExport',
    },
    {
      name: 'När ska hästen utföras?',
      description: 'Ange det datum utförseln kommer att ske.',
      fields: ['exportDate'],
      hidden: 'values?.horseSwitchButtonTemporaryExport',
    },
    {
      name: 'När kom hästen tillbaka till Sverige?',
      description: 'Ange datum då återinförseln gjordes.',
      fields: ['importDate'],
      hidden: '!values?.horseSwitchButtonTemporaryExport',
    },
  ],
  fieldOptions: [
    {
      horseSwitchButtonTemporaryExport: { hidden: false },
      horseImport: {
        hidden: '!values?.horseSwitchButtonTemporaryExport || values?.horse ',
        output: 'horse',
      },
      horseSearch: {
        hidden: 'values?.horseSwitchButtonTemporaryExport || values?.horse',
      },
      exportAuction: { hidden: 'values?.exportReason !== "Auktion"' },
      exportPersonLabel: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportPersonName: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportPersonAddress1: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportPersonAddress2: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportPersonZipAndCity: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportPersonRegion: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportPersonCountry: {
        hidden: '!values?.horse || values.horseSwitchButtonTemporaryExport',
      },
      exportStallionName: { hidden: 'values?.exportReason !== "Avel"' },
      exportMareStatus: { hidden: 'values?.exportReason !== "Avel"' },
      exportFoalSex: {
        hidden:
          'values?.exportReason !== "Avel" || values?.exportMareStatus !== "Föl vid sidan"',
      },
      exportFoalFather: {
        hidden:
          'values?.exportReason !== "Avel" || values?.exportMareStatus !== "Föl vid sidan"',
      },
      exportFoalNumber: {
        hidden:
          'values?.exportReason !== "Avel" || values?.exportMareStatus !== "Föl vid sidan"',
      },
      exportFoalChipNr: {
        hidden:
          'values?.exportReason !== "Avel" || values?.exportMareStatus !== "Föl vid sidan"',
      },
      exportStallionCoveredName: {
        hidden:
          'values?.exportReason !== "Avel" || values?.exportMareStatus !== "Dräktig"',
      },
      exportFoalinfo: {
        hidden:
          'values?.exportReason !== "Avel" || values?.exportMareStatus !== "Föl vid sidan"',
      },
      exportDate: {
        min: 'new Date()',
      },
      importDate: {
        max: 'new Date()',
      },
    },
  ],
  provider: 'email',
};
