import {
  useGetHorseImportExport,
  useGetHorsesByOwnerships,
  useGetPersonalInformation,
  useGetTrainerHorses,
} from '@apis';
import { AuthenticatedUser } from '@generated/account/src';
import { HorseImportExport } from '@generated/horses/src';
import { HorseInTraining } from '@generated/licenseholders/src';
import { OwnershipHorse } from '@generated/sportactors/src';
import { PersonalInformation } from '@generated/user/src';
import Loading from '@primitives/Loading';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import React, { createContext, useMemo } from 'react';

interface WithHorsesProps {
  user: AuthenticatedUser;
  children: React.ReactNode;
}

interface ContextProps {
  ownedHorses: OwnershipHorse[];
  trainedHorses: HorseInTraining[];
  exportedHorses: HorseImportExport[];
  userData: PersonalInformation;
}

export const HorsesContext = createContext<ContextProps>({
  ownedHorses: [],
  trainedHorses: [],
  exportedHorses: [],
  userData: {} as PersonalInformation,
});

export function WithHorses({ user, children }: WithHorsesProps): JSX.Element {
  /* Wait for loading */
  const { data: userData, loading: loadingUser } = useGetPersonalInformation(
    user.licenseId,
  );
  const { data: exportedHorses, loading: loadingExportedHorses } =
    useGetHorseImportExport(false);

  if (loadingUser || loadingExportedHorses) {
    return <Loading />;
  }

  return hasRole(user, roleEnum.Delegare || roleEnum.Foretredare) ? (
    <WithHorsesAndPersonalData
      user={user}
      userData={userData}
      exportedHorses={exportedHorses}
    >
      {children}
    </WithHorsesAndPersonalData>
  ) : (
    <HorsesContext.Provider
      value={{
        userData,
        ownedHorses: [],
        trainedHorses: [],
        exportedHorses,
      }}
    >
      {children}
    </HorsesContext.Provider>
  );
}

export function WithHorsesAndPersonalData({
  user,
  userData,
  exportedHorses,
  children,
}: WithHorsesProps & {
  userData: PersonalInformation;
  exportedHorses: HorseImportExport[];
}): JSX.Element {
  /* Wait for loading */
  const { data: ownedHorses, loading: loadingHorses } =
    useGetHorsesByOwnerships(user.licenseId);
  const { data: trainedHorses, loading: loadingTrainedHorses } =
    useGetTrainerHorses(user.licenseId);

  const value = useMemo(
    () => ({
      ownedHorses: ownedHorses ?? [],
      trainedHorses: trainedHorses ?? [],
      exportedHorses: exportedHorses ?? [],
      userData,
    }),
    [ownedHorses, trainedHorses, userData, exportedHorses],
  );

  if (loadingHorses || loadingTrainedHorses) {
    return <Loading />;
  }

  return (
    <HorsesContext.Provider value={value}>{children}</HorsesContext.Provider>
  );
}
