import { useGetHorse, useGetHorsePedigree } from '@apis';
import HorseInfoBase from '@components/HorseInfo';
import {
  GetPedigreePedigreetreeEnum,
  HorseImportExport,
} from '@generated/horses/src';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import { formatTz } from '@utils/dateUtils';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormField } from './definitions/_fields';

type Props = {
  def: FormField;
  horseId: number;
  exportedHorse: HorseImportExport;
  onReset: () => void;
};

export function HorseExportInfo({
  def,
  horseId,
  exportedHorse,
  onReset,
}: Props): JSX.Element {
  const { setFieldValue } = useFormikContext();

  const { data: horseData, loading: loadingHorse } = useGetHorse(horseId ?? 0);
  const { data: pedigree, loading: loadingPedigree } = useGetHorsePedigree(
    horseId ?? 0,
    GetPedigreePedigreetreeEnum.SMALL,
  );

  if (loadingHorse || !horseId || loadingPedigree) {
    return null;
  }

  const details = [];
  details.push({
    label: 'Tillfälligt utförd till',
    value: exportedHorse?.swedishName,
  });
  details.push({ label: 'Orsak till utförsel', value: exportedHorse?.cause });
  details.push({
    label: 'Datum för utförsel',
    value: formatTz(exportedHorse?.fromDate, 'yyyy-MM-dd'),
  });
  details.push({ label: 'Ägare', value: horseData?.owner?.name });
  details.push({ label: 'Far', value: pedigree?.father?.name });
  details.push({ label: 'Mor', value: pedigree?.mother?.name });

  return (
    <Box
      key={def.name}
      width={'100%'}
      sx={{ gridColumn: ['span 1', , 'span 3'] }}
    >
      <HorseInfoBase
        name={`${horseData?.name}`}
        text={`${horseData?.registrationNumber}, ${horseData?.horseGender?.text}, ${horseData?.dateOfBirthDisplayValue}`}
        details={details}
      />
      <ButtonLink
        sx={{ float: 'right' }}
        onClick={() => {
          if (def.name === 'horseExportInfo') {
            onReset();
            setFieldValue('horse', null);
          }
        }}
      >
        Välj annan häst
      </ButtonLink>
    </Box>
  );
}
