import Content from '@components/ContentBlock/Content';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import theme from '@main/theme';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { PaperPlane } from 'styled-icons/fa-solid';

import ListIssue from './list';

interface Props {
  user: AuthenticatedUser;
}

function Issues({ user }: Props): JSX.Element {
  const { url, path } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(search);
  const isNew = params.get('new') === 'true';
  const newIssueButton = params.get('newIssueButton');

  const newIssue = (): void => {
    history.push(`/tjanster/nytt?templateId=${newIssueButton}`);
  };

  return (
    <PageLayout
      headline="Ärenden"
      singleCard
      tabs={[
        { text: 'Pågående ärenden', to: `${url}`, exact: true },
        { text: 'Avslutade ärenden', to: `${url}/avslutade` },
      ]}
    >
      <Switch>
        <Route path={`${path}/avslutade`} exact>
          <Content slug="arenden-avslutade-arenden" />
          <br />
          <ListIssue user={user} closed />
        </Route>
        <Route path={`${path}`}>
          {isNew && (
            <Box
              color={theme.colors.green}
              backgroundColor={theme.colors['light-green']}
              padding={'10px'}
              marginBottom={'10px'}
            >
              Ärendet har skickats till Svensk Galopp för handläggning.
            </Box>
          )}
          {newIssueButton && (
            <Box pb={2}>
              <Button onClick={newIssue} sx={{ padding: '10px 10px' }}>
                Nytt ärende
                <PaperPlane size={12} style={{ marginLeft: '8px' }} />
              </Button>
            </Box>
          )}
          <Content slug="arenden-pagaende-arenden" />

          <br />
          <ListIssue user={user} />
        </Route>
      </Switch>
    </PageLayout>
  );
}

export default withUser(Issues, { onlyAuthenticated: false });
