import { Role } from '@generated/account/src';

import { BankkontoGiroanmalan } from './BankkontoGiroanmalan';
import { BidragsansokanUtlandsktDraktigtSto } from './BidragsansokanUtlandsktDraktigtSto';
import { EpostfakturaAnmalan } from './EpostfakturaAnmalan';
import { NamnansokanForHast } from './NamnansokanForHast';
import { TillfalligUtOchAterinforselAvHast } from './TillfalligUtOchAterinforselAvHast';
import { TraningslistaAnmalanAvHastIOchUrTraning } from './TraningslistaAnmalanAvHastIOchUrTraning';

export type Template = {
  id: number;
  description?: string;
  actorType: string;
  template?: string;
  newIssueButton?: boolean;
  fields: {
    name: string;
    description?: string;
    fields: string[];
    hidden?: string | boolean;
    conditions?: {
      text: string;
      condition: string;
    }[];
  }[];
  fieldOptions: {
    [key: string]: {
      hidden?: string | boolean;
      output?: string;
      outputAfter?: string;
      outputBefore?: string;
      max?: string | number;
      min?: string | number;
      showUser?: string | boolean;
      pattern?: string;
    };
  }[];
  provider: string;
  roles?: Array<Role>;
  confirm?: {
    link: string;
    linkText: string;
    text: string;
  };
};

export const templateData: Template[] = [
  NamnansokanForHast,
  BankkontoGiroanmalan,
  BidragsansokanUtlandsktDraktigtSto,
  TraningslistaAnmalanAvHastIOchUrTraning,
  EpostfakturaAnmalan,
  TillfalligUtOchAterinforselAvHast,
];
