import PSwitch from '@primitives/Switch';
import { FieldHookConfig, useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';

import Error from './Error';
type Props = {
  label?: string;
  name?: string;
} & FieldHookConfig<string> &
  InputHTMLAttributes<HTMLInputElement>;

function Switch({ ...props }: Props): JSX.Element {
  const [field, meta] = useField<string>({ ...props });

  const inputProps = {
    ...field,
    className: meta.touched && meta.error ? 'errorInput' : undefined,
  };

  return (
    <>
      <PSwitch {...inputProps} />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

export default Switch;
