type EuropeanCountry = {
  code: string;
  name: string;
  swedishName: string;
};

export const europeanCountries: EuropeanCountry[] = [
  { code: 'AL', swedishName: 'Albanien', name: 'Albania' },
  { code: 'AD', swedishName: 'Andorra', name: 'Andorra' },
  { code: 'BY', swedishName: 'Belarus', name: 'Belarus' },
  { code: 'BE', swedishName: 'Belgien', name: 'Belgium' },
  {
    code: 'BA',
    swedishName: 'Bosnien och Hercegovina',
    name: 'Bosnia and Herzegovina',
  },
  { code: 'BG', swedishName: 'Bulgarien', name: 'Bulgaria' },
  { code: 'CY', swedishName: 'Cypern', name: 'Cyprus' },
  { code: 'DK', swedishName: 'Danmark', name: 'Denmark' },
  { code: 'EE', swedishName: 'Estland', name: 'Estonia' },
  { code: 'FI', swedishName: 'Finland', name: 'Finland' },
  { code: 'FR', swedishName: 'Frankrike', name: 'France' },
  { code: 'GR', swedishName: 'Grekland', name: 'Greece' },
  { code: 'IE', swedishName: 'Irland', name: 'Ireland' },
  { code: 'IS', swedishName: 'Island', name: 'Iceland' },
  { code: 'IT', swedishName: 'Italien', name: 'Italy' },
  { code: 'HR', swedishName: 'Kroatien', name: 'Croatia' },
  { code: 'LV', swedishName: 'Lettland', name: 'Latvia' },
  { code: 'LI', swedishName: 'Liechtenstein', name: 'Liechtenstein' },
  { code: 'LT', swedishName: 'Litauen', name: 'Lithuania' },
  { code: 'LU', swedishName: 'Luxemburg', name: 'Luxembourg' },
  { code: 'MT', swedishName: 'Malta', name: 'Malta' },
  { code: 'MD', swedishName: 'Moldavien', name: 'Moldova' },
  { code: 'MC', swedishName: 'Monaco', name: 'Monaco' },
  { code: 'ME', swedishName: 'Montenegro', name: 'Montenegro' },
  { code: 'NL', swedishName: 'Nederländerna', name: 'Netherlands' },
  { code: 'MK', swedishName: 'Nordmakedonien', name: 'North Macedonia' },
  { code: 'NO', swedishName: 'Norge', name: 'Norway' },
  { code: 'PL', swedishName: 'Polen', name: 'Poland' },
  { code: 'PT', swedishName: 'Portugal', name: 'Portugal' },
  { code: 'RO', swedishName: 'Rumänien', name: 'Romania' },
  { code: 'RU', swedishName: 'Ryssland', name: 'Russia' },
  { code: 'SM', swedishName: 'San Marino', name: 'San Marino' },
  { code: 'CH', swedishName: 'Schweiz', name: 'Switzerland' },
  { code: 'RS', swedishName: 'Serbien', name: 'Serbia' },
  { code: 'SK', swedishName: 'Slovakien', name: 'Slovakia' },
  { code: 'SI', swedishName: 'Slovenien', name: 'Slovenia' },
  { code: 'ES', swedishName: 'Spanien', name: 'Spain' },
  { code: 'GB', swedishName: 'Storbritannien', name: 'United Kingdom' },
  { code: 'SE', swedishName: 'Sverige', name: 'Sweden' },
  { code: 'CZ', swedishName: 'Tjeckien', name: 'Czechia' },
  { code: 'DE', swedishName: 'Tyskland', name: 'Germany' },
  { code: 'UA', swedishName: 'Ukraina', name: 'Ukraine' },
  { code: 'HU', swedishName: 'Ungern', name: 'Hungary' },
  { code: 'VA', swedishName: 'Vatikanstaten', name: 'Vatican City' },
  { code: 'AT', swedishName: 'Österrike', name: 'Austria' },
  { code: 'TR', swedishName: 'Turkiet', name: 'Turkey' },
];
